// /*jshint multistr: true */

//
// Enable Bootstrap tooltips
//
document.addEventListener("DOMContentLoaded", function () {
  [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]')).map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });
});
