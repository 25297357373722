document.addEventListener('DOMContentLoaded', (event) => {
  const formControls = document.querySelectorAll('.display-form-error');

  formControls.forEach((control) => {
    control.addEventListener('input', function() {
      const parent = this.closest('.form-floating');
      if (parent && parent.classList.contains('form-group-invalid')) {
        parent.classList.remove('form-group-invalid');
      }
    });
  });
});
