window.periodToDateRange = function (period, customStart, customEnd) {
  document.querySelectorAll(".d-not-this-quarter").forEach((el) => {
    el.classList.toggle("d-none", period == "this-quarter");
  });
  document.querySelectorAll(".d-this-quarter").forEach((el) => {
    el.classList.toggle("d-none", period != "this-quarter");
  });

  let from;
  let to = null;
  const quarter = Math.floor(new Date().getMonth() / 3);
  const days = 24 * 60 * 60 * 1000;
  const startOfDay = new Date().setHours(0, 0, 0, 0);
  const startOfMonth = new Date(startOfDay).setDate(1);
  const startOfQuarter = new Date(new Date().getFullYear(), quarter * 3, 1);
  const startOfYear = new Date(startOfMonth).setMonth(0);

  if (period != "custom") Cookies.set("period", period);

  switch (period) {
    case "custom":
      customStart.setHours(0);
      customStart.setMinutes(0);
      customStart.setSeconds(0);
      customEnd.setHours(23);
      customEnd.setMinutes(59);
      customEnd.setSeconds(59);
      from = customStart;
      to = customEnd;
      break;

    case "today":
      from = new Date(startOfDay);
      break;

    case "last-7":
      from = new Date(startOfDay - 7 * days);
      break;

    case "last-30":
      from = new Date(startOfDay - 30 * days);
      break;

    case "last-60":
      from = new Date(startOfDay - 60 * days);
      break;

    case "last-90":
      from = new Date(startOfDay - 90 * days);
      break;

    case "last-120":
      from = new Date(startOfDay - 120 * days);
      break;

    case "last-365":
      from = new Date(startOfDay - 365 * days);
      break;

    case "this-month":
      from = new Date(startOfMonth);
      break;

    case "last-month":
      from = dateLessMonth(startOfMonth, 1).from;
      to = dateLessMonth(startOfMonth, 1).to;
      break;

    case "month-less-2":
      from = dateLessMonth(startOfMonth, 2).from;
      to = dateLessMonth(startOfMonth, 2).to;
      break;

    case "month-less-3":
      from = dateLessMonth(startOfMonth, 3).from;
      to = dateLessMonth(startOfMonth, 3).to;
      break;

    case "month-less-4":
      from = dateLessMonth(startOfMonth, 4).from;
      to = dateLessMonth(startOfMonth, 4).to;
      break;

    case "month-less-5":
      from = dateLessMonth(startOfMonth, 5).from;
      to = dateLessMonth(startOfMonth, 5).to;
      break;

    case "month-less-6":
      from = dateLessMonth(startOfMonth, 6).from;
      to = dateLessMonth(startOfMonth, 6).to;
      break;

    case "month-less-7":
      from = dateLessMonth(startOfMonth, 7).from;
      to = dateLessMonth(startOfMonth, 7).to;
      break;

    case "month-less-8":
      from = dateLessMonth(startOfMonth, 8).from;
      to = dateLessMonth(startOfMonth, 8).to;
      break;

    case "month-less-9":
      from = dateLessMonth(startOfMonth, 9).from;
      to = dateLessMonth(startOfMonth, 9).to;
      break;

    case "month-less-10":
      from = dateLessMonth(startOfMonth, 10).from;
      to = dateLessMonth(startOfMonth, 10).to;
      break;

    case "month-less-11":
      from = dateLessMonth(startOfMonth, 11).from;
      to = dateLessMonth(startOfMonth, 11).to;
      break;

    case "month-less-12":
      from = dateLessMonth(startOfMonth, 12).from;
      to = dateLessMonth(startOfMonth, 12).to;
      break;

    case "this-quarter":
      from = new Date(startOfQuarter);
      break;

    case "last-quarter":
      var d = new Date(startOfQuarter);
      from = new Date(d.getFullYear(), d.getMonth() - 3, d.getDate(), d.getHours(), d.getMinutes(), 0);
      to = new Date(startOfQuarter - 1);
      break;

    case "this-year":
      from = new Date(startOfYear);
      break;

    case "last-year":
      var d = new Date(startOfYear);
      from = new Date(d.getFullYear() - 1, d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), 0);
      to = new Date(startOfYear - 1);
      break;

    default:
      from = null;
      to = null;
  }

  let result = {};
  if (from) result.start_date = from.toISOString();
  if (to) result.end_date = to.toISOString();

  return result;
};

window.setPeriod = function (period) {
  var changePeriodEl = document.getElementById("changePeriod");
  changePeriodEl.value = period;
  window.selectors["changePeriod"].setChoiceByValue(period);

  // Create a new 'change' event and dispatch it.
  changePeriodEl.dispatchEvent(new Event("change"));
};

function dateLessMonth(startOfMonth, m) {
  var d = new Date(startOfMonth);

  // Calculate 'from' date by subtracting 'm' months
  var from = new Date(d.getFullYear(), d.getMonth() - m, d.getDate(), d.getHours(), d.getMinutes(), 0);

  // Calculate 'to' date by adding one month to the 'from' date
  var to = new Date(from.getFullYear(), from.getMonth() + 1, from.getDate(), from.getHours(), from.getMinutes(), 0);

  return { from, to };
}
