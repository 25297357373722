//
// Color Picker
//
document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll(".color-preview").forEach((el) => {
    const colorPickerInput = el.previousElementSibling.querySelector("input");
    el.addEventListener("change", (e) => {
      colorPickerInput.value = e.target.value;
    });

    document.querySelectorAll("#sample-colors button").forEach((el) => {
      el.addEventListener("click", () => {
        colorPickerInput.value = el.value = el.textContent;
      });
    });
    colorPickerInput.addEventListener("change", (e) => {
      el.value = e.target.value;
    });
  });
});
