//
// Sidebar slider
//
document.addEventListener("DOMContentLoaded", () => {
  const mainSidebar = document.getElementById("main-sidebar");
  const slide = document.getElementById("slide");
  const bodyEl = document.querySelector("body");

  // Get the variables passed from the query string
  const params = Object.fromEntries(new URLSearchParams(window.location.search).entries());

  // If passing in areas in param, override the cookie
  if (params.areas) {
    Cookies.set("areas", `["${params.areas.split(",").join('","')}"]`);
  }

  if (mainSidebar) {
    // Assuming params, Cookies, and the necessary DOM elements are already defined
    let sidebarIn = params.sidebar ? params.sidebar === "in" : Cookies.get("sidebarIn") === "true";

    // Set the initial state of the sidebar
    bodyEl.classList.toggle("in", sidebarIn);

    // Event listener for toggling the sidebar on click
    slide.addEventListener("click", () => {
      sidebarIn = !sidebarIn;
      Cookies.set("sidebarIn", sidebarIn);

      // Toggle the 'in' class based on the sidebarIn state
      bodyEl.classList.toggle("in", sidebarIn);

      // Remove 'over' class whenever the sidebar is toggled
      if (sidebarIn) bodyEl.classList.remove("over");
    });

    // Event listener for adding 'over' class on mouseover
    mainSidebar.addEventListener("mouseover", () => {
      bodyEl.classList.add("over");
    });

    // Event listener for removing 'over' class on mouseout
    mainSidebar.addEventListener("mouseout", () => {
      bodyEl.classList.remove("over");
    });
  }
});
