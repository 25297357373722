//
// Adds simple handling of non-GET links
//
document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll("a[data-method]").forEach((el) => {
    el.addEventListener("click", (e) => {
      e.preventDefault();

      const form = document.createElement("form");
      form.setAttribute("action", el.getAttribute("href"));
      form.setAttribute("method", "post");

      const methodEl = document.createElement("input");
      methodEl.setAttribute("name", "_method");
      methodEl.setAttribute("type", "hidden");
      methodEl.value = el.getAttribute("data-method");
      form.append(methodEl);

      const authTokenEl = document.createElement("input");
      authTokenEl.setAttribute("name", "authenticity_token");
      authTokenEl.setAttribute("type", "hidden");
      authTokenEl.value = document.querySelector("meta[name=csrf-token]").getAttribute("content");
      form.append(authTokenEl);

      document.getElementsByTagName("body")[0].append(form);
      form.submit();
    });
  });
});
